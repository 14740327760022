<template>
  <component :is="agencyData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert :show="agencyData === undefined" variant="danger">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No agency found with this agency id. Check
        <b-link :to="{ name: 'agencies'}" class="alert-link">Agency List</b-link>
        for other agency.
      </div>
    </b-alert>

    <b-tabs v-if="agencyData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon class="mr-0 mr-sm-50" icon="UserIcon" size="16"/>
          <span class="d-none d-sm-inline">Agence</span>
        </template>
        <agency-edit-tab-account :agency-data="agencyData" class="mt-2 pt-75"/>
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon class="mr-0 mr-sm-50" icon="UsersIcon" size="16"/>
          <span class="d-none d-sm-inline">Équipe</span>
        </template>
        <agency-edit-tab-members :agency-data="agencyData" :agency-id="currentAuthUser" class="mt-2 pt-75"/>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {BAlert, BCard, BLink, BTab, BTabs,} from 'bootstrap-vue';
import {ref, onMounted} from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import AgencyEditTabAccount from './AgencyEditTabAccount.vue';
import AgencyEditTabMembers from './AgencyEditTabMembers.vue';
import {useAgencyApi} from "@/modules/agency/composables/use-agency-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    AgencyEditTabAccount,
    AgencyEditTabMembers

  },
  setup(props, context) {
    const {logger, httpClient, route, router, store} = useApplicationContext();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();

    const {getAgency} = useAgencyApi();

    const agencyData = ref({});
    const currentAuthUser = ref({});
    const currentAgencyId = ref(null)

    const fetchAgency = async () => {
      try {
        agencyData.value = await getAgency(currentAgencyId.value);
      } catch(e) {
        displayErrorMessage("Une erreur est survenue en essayant de chercher l'agence ");
      }
    };

    onMounted(async () => {
      try{
        if (route.value.params.agency_id) {
          currentAgencyId.value = route.value.params.agency_id;
        } else {
          currentAuthUser.value = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];
          currentAgencyId.value = currentAuthUser.value.agency_id;
        }
        fetchAgency()

      } catch (error) {
        console.error(e)
      }
    });



    return {
      agencyData,
      currentAuthUser
    };
  }
};
</script>

<style>

</style>
